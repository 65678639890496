import { nanoid } from 'nanoid';
import { random, isEmpty, toString } from "lodash";
import device from "current-device";

jQuery( function( $ ) {
    $('.off-canvas-content').click(function(){
        $('.off-canvas').addClass('is-open');
    });
    $('.off-canvas .menu-lines').click(function(){
        $('.off-canvas').removeClass('is-open');
    });
    $('.inside-header .menu .menu-item-has-children').attr('data-glyph-after','');
     $('.off-canvas .menu-item-has-children').append('<div class="icon"><i class="fa-solid fa-chevron-down"></i></div>');
    // $('.off-canvas .menu-item-has-children i').click(function(){
    //     $('.off-canvas .sub-menu').toggle();
    //     $('.off-canvas .icon').addClass('acti');
    // });

    $(".at-title").click(function () {
        $(this).toggleClass("active").next(".at-tab").slideToggle().parent().siblings().find(".at-tab").slideUp().prev().removeClass("active");
      });

      $(".off-canvas .menu-item-has-children .icon").click(function () {
        $(this).toggleClass("active");
        $('.off-canvas .menu-item-has-children .sub-menu').toggle();
      });



      $(window).scroll(function () {
        if ( window.pageYOffset >  $('section#thanhtuu_home').offset().top - $(window).height() + 80) {
            $('.counter').each(function () {
            var $this = $(this),
              countTo = $this.attr('data-count')
    
              $({ countNum: $this.text() }).animate(
              {
                countNum: countTo,
              },
    
              {
                duration: 1000,
                easing: 'linear',
                step: function () {
                  $this.text(Math.floor(this.countNum))
                },
                complete: function () {
                  $this.text(this.countNum)
                  //alert('finished');
                },
              },
            )
          })
        } else {
          return
        }
      });
  
});

